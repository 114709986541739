import React from "react"
import ReactDOM from "react-dom"
import Navbar from "./components/Navbar"
import Main from "./components/Main"

export default function App() {
  return (
    <div class="container">
      <Navbar />
      <Main />
    </div>
  )
}